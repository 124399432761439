@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container] .#{$moduleNameSpace} {
  display: flex;
  margin: auto;

  width: 100%;
  justify-content: center;
  align-items: center;

  .get-app-text {
    display: none;

    @media #{$medium-up} {
      display: inline;
      position: relative;
      bottom: 14px;
    }
  }

  .row {
    padding: 0;
    background-color: $color-gray-s05;
    text-align: center;
    height: 60px;
    padding-top: 5px;

    @media #{$medium-up} {
      height: 79px;
      padding-top: 12px;
    }
  }
  .column {
    padding: 0;
  }
  img {
    max-height: 44px;

    @media #{$medium-up} {
      max-height: 49px;
    }
  }

  span, a {
    margin-left: $spacing-10;
    bottom: 15px;
  }

  .spa-btn--small {
    line-height: 1;
  }
}
